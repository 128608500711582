@import 'reactjs-popup/dist/index.css';

@font-face {
    font-family: 'UighurFont';
    src: url('../public/assets/fonts/UKIJTuzG.ttf') format('truetype');
}

@font-face {
    font-family: 'UighurFontBold';
    src: url('../public/assets/fonts/UKIJTuzGB.ttf') format('truetype');
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f5f5f5;
}

.material-symbols-outlined {
    font-variation-settings:
        'FILL'1,
        'wght'400,
        'GRAD'0,
        'opsz'24
}

// Classes for popup
.popup-content {
    padding: 0;
    background-color: white;
    border-radius: 24px;
    border: 0;
    width: 90%;
    max-width: 800px;
}